import { HTTPStatus } from '../../core/enums/HTTPStatus';
import { userStore } from '../userModule';
import { maintenanceStore } from '../maintenancesModule';
import { USER_BLOCKED_ERR_MESSAGE } from '../../core/constants/apiConstants';
import { SSO_LOGIN_URL } from '../../core/constants/urls';

class ErrorHandlerService {
  handle({ response = {} }) {
    if (
      response.status === HTTPStatus.NotImplemented
      || response.status === HTTPStatus.BadGataway
      || response.status === HTTPStatus.ServiceUnavailable 
      || response.status === HTTPStatus.GatawayTimeOut
      || response.status === HTTPStatus.NotSupportedVersion
    ) {
      maintenanceStore.setIsUnderMaintenance(true);
    } else if (response.data?.errorMessage === USER_BLOCKED_ERR_MESSAGE) {
      const { _userName } = userStore.blockedUser;
      userStore.setBlockedUser({
        _userName,
        isBlocked: true,
      });
    } else if (response.status === HTTPStatus.Unauthorized) {
      window.location.href = SSO_LOGIN_URL;
    }
  }
}

export const errorHandlerService = new ErrorHandlerService();