import React, { useLayoutEffect } from 'react';

import { SSO_LOGIN_URL } from '../../../core/constants/urls';

const Login = () => {

  useLayoutEffect(() => {
    // window.location.href = SSO_LOGIN_URL;
  }, []);

  return ( null );
};

export default Login;
