import { BACKOFFICE_APPLICATION_ID } from '../../core/constants/util';
import { applicationsStore } from '../applicationsModule/applicationsStore';
import { translationStore } from '../translationModule/translationStore';
import { gamesStore } from '../gamesModule/gamesStore';
import { permissionStore } from '../permissionsModule';
import { currenciesStore } from '../currenciesModule';
import { platformStore } from '../platformModule';
import { userStore } from '../userModule';
import { authStore } from '../authModule';

class AppModule {
  init = async () => {
    const backofficeLanguages = await applicationsStore.getBackofficeLanguages(BACKOFFICE_APPLICATION_ID);
    const isBackofficeLanguagesOk = Array.isArray(backofficeLanguages);
    const languageId = isBackofficeLanguagesOk ? backofficeLanguages.find(({ isDefault }) => isDefault)?.id : null;

    if (!authStore.manualAuth) {
      // There is no need refresh the token after login.
      try {
        await authStore.refreshToken();
      } catch (error) {
        return false;
      }
    }
    currenciesStore.getCurrencies();
    const userInfo = await userStore.getUserInfo();
    await Promise.all([
      translationStore.getBackofficeTranslations({ languageId }, BACKOFFICE_APPLICATION_ID),
      permissionStore.getPermissionsUserId(userInfo.id),
      platformStore.getPlatformsPartners({}),
      userStore.getCustomizationConfig(),
      gamesStore.getGameLines(),

    ]);
    return true;
  };
}

export const appModule = new AppModule();
