import currencies from '../constants/currencies';

export const getCurrency = (value, currentCurrency, fromBetReports) => {
    const { currencyCode, rate, precision } = currentCurrency;
    let config = currencies[currencyCode];
    if (!config && currencyCode) {
        config = {
            sign: currencyCode,
            fraction: precision,
            pre: false
        };
    }
    if (!config || (!value && value !== 0)) return value;
    const { sign, pre } = config;
    const amountPrecision = 10 ** precision > 1 ? 10 ** precision : 100;
    let currency = Math.abs(value);
    currency = value >= 0 ? currency : `-${currency}`;
    currency = fromBetReports ? currency : Math.trunc((value / rate) * amountPrecision) / amountPrecision;
    return pre ? `${sign}${currency.toLocaleString()}` : `${currency.toLocaleString()}${sign}`;
};

export const amountExchangerByRate = (value, precision) => {
    const amountExponentiation = 10 ** precision;
    const amountPrecision = amountExponentiation > 1 ? amountExponentiation : 100;
    return Math.trunc(value * amountPrecision) / amountPrecision;
};

export const convertByCurrency = (value, currentCurrency, isToLocaleString = true) => {
    if (value === 0) return '0';
    if (!value) return '-';
    const { rate, precision } = currentCurrency;
    const amountPrecision = 10 ** precision > 1 ? 10 ** precision : 100;
    let convertedValue = Math.trunc((value / rate) * amountPrecision) / amountPrecision;
    return isToLocaleString ? convertedValue.toLocaleString() : convertedValue;
};
