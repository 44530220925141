import React, { useEffect, useState, Suspense, useMemo } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { authStore, gamesStore, appModule, platformStore } from '../../../module';
import { combineQuery } from '../../../core/helpers/queryHelper';
import useQuery from '../../../hooks/useQuery';
import { Loader } from '../../components/shared';

const loginPagePath = '/login';

const Splash = observer(({
  history,
  location,
  children,
}) => {
  const { isAuth } = authStore;
  const [isLoading, setLoading] = useState(true);
  const { query, queryObject } = useQuery();
  const { pathname } = useLocation();

  const loginQuery = useMemo(() => {
    if (pathname !== loginPagePath) return '';
    const { skip, take, ...restQuery } = queryObject;
    return combineQuery(restQuery);
  }, [query, pathname]);

  useEffect(() => {
    if (isAuth) {
      setLoading(true);
    }
    appModule.init().then(isValid => {
      authStore.setAuth(!!isValid);
      if (!isValid) {
        history.push(`${loginPagePath}${loginQuery}`);
      } else {
        history.push({
          path: location.pathname,
          search: location.search,
        });
        gamesStore.getGames();
      }
      setLoading(false);
    });
  }, [isAuth]);

  return isLoading
    ? (
      <div className="splash__busy__loader_container">
        <Loader isBusy type="spinner" spinnerSize="big"/>
      </div>
    )
    : (
      <Suspense
        fallback={(
          <Loader isBusy type="spinner"/>
        )}
      >
        {children}
      </Suspense>
    );
});

export default withRouter(Splash);
