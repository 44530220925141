import { action, computed, flow, makeObservable, observable } from 'mobx';

import { downloadFileFromHttpResponse } from '../../core/helpers/fileDownloader';
import { HttpResponse } from '../../core/classes/HttpResponse';
import { currenciesService } from './currenciesService';

class CurrenciesStore {
  /** @type {HttpResponse<Array<Currency>>}*/
  currencies = new HttpResponse([]);

  /** @type {Currency}*/
  current = {};

  /** @type {HttpResponse<CurrenciesListResponse>} */
  currenciesList = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<CurrencyChartResponse>} */
  currencyChart = new HttpResponse([]);

  constructor() {
    makeObservable(this, {
      currencies: observable,
      currenciesList: observable,
      currencyChart: observable,
      current: observable.struct,
      defaultCurrency: computed,
      setCurrencies: action.bound,
      setCurrentCurrency: action.bound,
      setCurrenciesList: action.bound,
      setCurrencyChart: action.bound,
      getCurrencies: flow.bound,
      getCurrenciesList: flow.bound,
      getCurrencyChart: flow.bound,
    });
  }

  /**
   * @name defaultCurrency
   * @return {Currency}
   */
  get defaultCurrency() {
    return this.currencies.data.find(({ isDefault }) => isDefault);
  }

  /**
   * @name setCurrencies
   * @param {HttpResponse<Array<Currency>>} currencies
   * @returns {void}
   */
  setCurrencies = (currencies) => {
    this.currencies = currencies;
  };

  /**
   * @name setCurrentCurrency
   * @param {Currency} currency
   * @returns {void}
   */
  setCurrentCurrency = (currency) => {
    this.current = currency;
  };

  /**
   * @name setCurrenciesHistory
   // * @param {CurrenciesListResponse} currenciesList
   * @return {void}
   */
  setCurrenciesList = (currenciesList) => {
    this.currenciesList = currenciesList;
  }

  setCurrencyChart = (currencyChart) => {
    this.currencyChart = currencyChart;
  }

  /**
   * @name getCurrencies
   * @returns {Generator<*, void, *>}
   */
  getCurrencies = function* () {
    this.setCurrencies(this.currencies.fetching());
    const { items } = yield currenciesService.getCurrencies();
    items.sort(({ currencyCode }, { currencyCode: currencyCodeSecond }) => {
      if (currencyCode < currencyCodeSecond) return -1;
      if (currencyCode > currencyCodeSecond) return 1;
      return 0;
    });
    this.setCurrencies(this.currencies.fetched(items));
  };

  /**
   * @name getCurrenciesList
   * @param {CurrenciesListParams=} params
   * @return {Generator<*, void, *>}
   */
  getCurrenciesList = function* (params = {}) {
    this.setCurrenciesList(this.currenciesList.fetching());
    const result = yield currenciesService.getCurrencies(params);
    this.setCurrenciesList(this.currenciesList.fetched(result));
  }

  /**
   * @name getCurrencyChart
   * @param {CurrencyChartParams=} params
   * @param {ApiOption=} option
   * @return {Generator<Promise<CurrencyChartResponse>, void, *>}
   */
  getCurrencyChart = function* (params = {}, apiOption) {
    this.setCurrencyChart(this.currencyChart.fetching());
    const result = yield currenciesService.getCurrencyChart(params, apiOption);
    this.setCurrencyChart(this.currencyChart.fetched(result));
  }

  /**
   * @name exportCurrencies
   * @param {ExportCurrenciesParams} params
   * @return {Promise<{File}>}
   */
  exportCurrencies = async (params) => {
    const res = await currenciesService.exportCurrencies(params);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'currencies.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

  /**
   * @name exportCurrenciesChart
   * @param {CurrencyChartParams} params
   * @return {Promise<{File}>}
   */
  exportCurrenciesChart = async (params) => {
    const res = await currenciesService.exportCurrenciesChart(params);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'currenciesChart.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

}

export const currenciesStore = new CurrenciesStore();
